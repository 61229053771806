import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import faltfluegeltuer_00 from '../../images/faltfluegel_00_1600.webp'
import faltfluegeltuer_01 from '../../images/faltfluegel_01_1600.webp'
import faltfluegeltuer_02 from '../../images/faltfluegel_02_1600.webp'
import faltfluegeltuer_03 from '../../images/faltfluegel_03_1600.webp'
import faltfluegeltuer_thumb_00 from '../../images/faltfluegel_00_400.webp'
import faltfluegeltuer_thumb_01 from '../../images/faltfluegel_01_400.webp'
import faltfluegeltuer_thumb_02 from '../../images/faltfluegel_02_400.webp'
import faltfluegeltuer_thumb_03 from '../../images/faltfluegel_03_400.webp'
import drehtuer_thumb_00 from '../../images/automatische_drehtuer_00_400.webp';

const context = {
  title: 'Automatische Faltflügeltür',
  description: 'Raumwunder für spezielle Fälle. Öffnet automatisch, zuverlässig und platzsparend',
  text: 'Eine automatische Faltflügeltür besteht aus sich ein- oder beidseitig öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in eine Festverglasung oder in ein Mauerwerk eingefasst werden. Die Einbaumaße einer Falttür bewegen sich meist in einer lichten Einbaubreite von 800 mm bis 2000 mm.\n\n' +
    'Die Faltflügeltüren ermöglichen es trotz engster Umgebung, in denen Automatikschiebtüren keinen Platz finden, die volle Durchgangsbreite zu nutzen und Ihren Eingangsbereich barrierefrei, hygienisch und zeitgemäß zu gestalten. Sie lassen sich durch ihre eigene Bauweise in fast allen Neubauten einfügen, eine Nachrüstung in Fluren oder Altbauten ist ohne großen Aufwand möglich. Zudem können automatische Falttüren unter Beachtung weiterer Bestimmungen auch im Bereich eines Flucht- und Rettungswegs verbaut werden. Faltflügeltüren sind daher häufig in Restaurants, Hotels, Krankenhäusern und Altersheimen im Einsatz.\n\n' +
    'Nicht von der Stange - jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    'modernisierung',
    'notStrom',
    //'personenfrequenz',
    'platzsparend',
    //'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    //'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: faltfluegeltuer_00,
      thumbnail: faltfluegeltuer_thumb_00,
      originalAlt: 'Automatische Faltflügeltür'
    },
    {
      original: faltfluegeltuer_01,
      thumbnail: faltfluegeltuer_thumb_01,
      originalAlt: 'Automatische Faltflügeltür'
    },
    {
      original: faltfluegeltuer_02,
      thumbnail: faltfluegeltuer_thumb_02,
      originalAlt: 'Automatische Faltflügeltür'
    },
    {
      original: faltfluegeltuer_03,
      thumbnail: faltfluegeltuer_thumb_03,
      originalAlt: 'Automatische Faltflügeltür'
    },
  ],
  configurator: false,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
